
import Overlay from "~/components/Chrome/components/Overlay/ChromeOverlay.vue";

import StatusContainer from "./StatusContainer.vue";
import StatusTitle from "./StatusTitle.vue";
import ModalListEntity from "./ModalListEntity.vue";

export default {
  components: {
    StatusContainer,
    StatusTitle,
    Overlay,
    ModalListEntity,
  },

  data() {
    return {
      modalOpen: false,
      selectedId: null,
    };
  },

  computed: {
    kanbanData() {
      let kanbanItems = [...this.$store.getters["lists/getKanbanData"]];
      return kanbanItems.sort((a, b) => a.status?.weight - b.status?.weight);
    },

    statuses() {
      let statuses = [...this.$store.getters["lists/getStatuses"]];
      return statuses.sort((a, b) => a.weight - b.weight);
    },

    numberOfColumns() {
      return this.kanbanData.length > 0 ? this.kanbanData.length : 1;
    },

    emptyState() {
      return this.kanbanData.length === 1 && this.kanbanData[0].entities.length === 0;
    },
  },

  methods: {
    getStatusName(status) {
      return `${status.name} ${this.kanbanData[status] && this.kanbanData[status].length > 0 ? ` (${this.kanbanData[status].length})` : ``}`;
    },

    openModal(id) {
      this.selectedId = id;
      this.modalOpen = true;
    },
  },
};
