// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_qvR82{align-items:center;cursor:pointer;display:flex;height:3.5rem;margin-bottom:1rem;-webkit-user-select:none;-moz-user-select:none;user-select:none}.scrim_Y4Usw{display:flex;height:100%;position:relative;width:100%}.scrim_Y4Usw:after{background:linear-gradient(270deg,#fff,transparent);bottom:0;content:\"\";position:absolute;right:0;top:0;width:1.5rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_qvR82",
	"scrim": "scrim_Y4Usw"
};
module.exports = ___CSS_LOADER_EXPORT___;
