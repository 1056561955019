// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".layoutSplitEnd_4regf,.layoutSplitStart_O\\+bsA{width:50%}.containerButtons_DCY9i,.containerTickBox_lskrw{display:flex}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layoutSplitEnd": "layoutSplitEnd_4regf",
	"layoutSplitStart": "layoutSplitStart_O+bsA",
	"containerButtons": "containerButtons_DCY9i",
	"containerTickBox": "containerTickBox_lskrw"
};
module.exports = ___CSS_LOADER_EXPORT___;
