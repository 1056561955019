// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_MxuC-{display:flex;flex-direction:row;padding:1rem 1rem 1rem .75rem}.overflow_Nr\\+V0{display:flex;overflow:auto;scroll-behavior:smooth;-webkit-overflow-scrolling:touch;scrollbar-color:var(--color-black-300) var(--color-white);scrollbar-width:thin}.overflow_Nr\\+V0::-webkit-scrollbar{height:1rem}.overflow_Nr\\+V0::-webkit-scrollbar-track{background:var(--color-white)}.overflow_Nr\\+V0::-webkit-scrollbar-thumb{background-color:var(--color-black-300);border:.25rem solid var(--color-white);border-radius:calc(1rem - var(--border-radius-medium))}.overflow_Nr\\+V0::-webkit-scrollbar-thumb:hover{border-width:.2rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_MxuC-",
	"overflow": "overflow_Nr+V0"
};
module.exports = ___CSS_LOADER_EXPORT___;
