
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      newName: this.$store.getters["lists/getStatusForRenaming"]?.name,
      oldName: this.$store.getters["lists/getStatusForRenaming"]?.name,
    };
  },

  methods: {
    renameStatus() {
      if (this.newName != "") {
        const listStatus = this.$store.getters["lists/getStatusForRenaming"];

        const updateListStatus = {
          name: this.newName,
          weight: listStatus.weight + 0,
          id: listStatus.id + "",
        };

        this.$store.dispatch("lists/updateListStatus", updateListStatus);

        this.$amplitude.track({ event_type: "Edit state" });
      }
    },
  },
};
