import UsersListPermissions from "~/graphql/Authentication/UsersListPermissions.gql";

export const getUsersListPermissions = function () {
    return {
        query: UsersListPermissions,
        update(data) {
            return data.users.filter(u => u.active);
        },
        error(error) {
            console.error("getUsers failed:", error);
        },
    };
};
