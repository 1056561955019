
import { getPropertyAddress } from "~/helpers/property-helpers.js"

export default {
  name: "KanbanItemProperty",

  inheritAttrs: false,

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    address(){
      return getPropertyAddress(this.item.property)
    }
  }
}
