
import ColumnToggle from "./components/ColumnToggle.vue";
import ColumnToggleItem from "./components/ColumnToggleItem.vue";

const collatorDa = new Intl.Collator("da", {
  numeric: true,
  sensitivity: "base",
});

export default {
  components: { ColumnToggle, ColumnToggleItem },

  inheritAttrs: false,

  props: {
    headers: {
      type: Array,
      required: true,
    },

    userPreferencesNameSpace: {
      type: String,
      default: () => {},
    },

    data: {
      type: Array,
      default: () => [],
    },

    tickBoxEnable: {
      type: Boolean,
      default: false,
    },

    tickBoxCheckedProperty: {
      type: String,
      default: undefined,
    },
  },

  collatorDa: collatorDa,

  data() {
    return {
      userPreferencesScoped: null,
      sortCustomFunction: this.headers.find((h) => h.sortActive)?.sortCustomFunction || null,
      dataProperty: this.headers.find((h) => h.sortActive)?.dataProperty || null,
      sortOrderDesc: this.headers.find((h) => h.sortActive)?.sortOrderDesc || null,
      tickBoxReference: this.data.map((d) => d[this.tickBoxCheckedProperty]),
    };
  },

  computed: {
    dataSorted() {
      let r = [];

      if (this.data && this.data.length > 0) {
        r = [...this.data];

        if (this.sortCustomFunction) {
          r.sort(this.sortCustomFunction);
        } else {
          const values = r.filter((value) => !!value[this.dataProperty]);
          const valueTypes = (values && values[0]) ?? {};

          if (typeof valueTypes[this.dataProperty] == "string") {
            r.sort(this.sortAsString);
          } else if (typeof valueTypes[this.dataProperty] == "number") {
            r.sort(this.sortAsNumber);
          }
        }

        if (this.sortOrderDesc) {
          r = r.reverse();
        }
      }

      return r;
    },

    tickBoxAllReference() {
      return this.data.length === this.data.filter((d) => d[this.tickBoxCheckedProperty]).length;
    },
  },

  watch: {
    tickBoxAllReference(value) {
      const t = JSON.parse(JSON.stringify(this.dataSorted));

      t.forEach((_, i) => {
        this.tickBoxReference[i] = value;

        t[i][this.tickBoxCheckedProperty] = value;
      });

      this.$emit("tickbox-change", t);
    },

    tickBoxReference() {
      const t = JSON.parse(JSON.stringify(this.dataSorted));

      t.forEach((_, i) => {
        t[i][this.tickBoxCheckedProperty] = this.tickBoxReference[i];
      });

      this.$emit("tickbox-change", t);
    },
  },

  async mounted() {
    this.$store.commit("sortableTable/setConfigureColumnsVisible", false);

    if (this.userPreferencesNameSpace) {
      this.userPreferencesScoped = await this.$userPreferences.getNamespace(this.userPreferencesNameSpace);
    } else {
      this.userPreferencesScoped = this.headers.map((header) => header.dataProperty);
    }

    this.$emit("userPreferencesInit", this.userPreferencesScoped);
  },

  methods: {
    tickboxChange(item, value) {
      this.$emit("tickbox-change", item, value);
    },

    tickboxAllChange(value) {
      this.dataSorted.forEach((item) => {
        this.tickboxChange(item, value);
      });
    },

    async change(value, userPreferencesProperty) {
      if (this.userPreferencesNameSpace) {
        await this.$userPreferences.setKey(this.userPreferencesNameSpace, userPreferencesProperty, value);
        this.userPreferencesScoped = await this.$userPreferences.getNamespace(this.userPreferencesNameSpace);

        this.$emit("userPreferencesChange", this.userPreferencesScoped);
      }
    },

    sort(item) {
      if (item.sortActive) {
        this.sortOrderDesc = !item.sortOrderDesc;

        item.sortOrderDesc = this.sortOrderDesc;
      } else {
        this.headers.forEach((h) => (h.sortActive = false));

        item.sortActive = true;

        this.sortCustomFunction = item.sortCustomFunction || null;
        this.dataProperty = item.dataProperty || null;
        this.sortOrderDesc = false;
      }
    },

    sortAsString(a, b) {
      const x = a[this.dataProperty] ?? "";
      const y = b[this.dataProperty] ?? "";

      return this.$options.collatorDa.compare(x, y);
    },

    sortAsNumber(a, b) {
      const x = a[this.dataProperty] ?? Number.MIN_SAFE_INTEGER;
      const y = b[this.dataProperty] ?? Number.MIN_SAFE_INTEGER;

      return x - y;
    },

    hasUserNameSpacedPreferences(userPreferences) {
      return (
        userPreferences &&
        userPreferences.blob &&
        userPreferences.blob[this.userPreferencesNameSpace] &&
        Object.keys(userPreferences.blob[this.userPreferencesNameSpace]).length > 0
      );
    },

    cellWidth(itemHeaderWidth, itemHeaderIndex) {
      return itemHeaderWidth || (itemHeaderIndex === 0 ? "1%" : "auto");
    },

    cellFixed(itemHeaderFixed, itemHeaderIndex) {
      return itemHeaderFixed !== undefined ? itemHeaderFixed : itemHeaderIndex === 0;
    },
  },
};
