export const sortByAddress = function (a, b) {
    if (!a || !a.address || !b || !b.address) {
        return;
    }
    let as = a.address.streetName;
    let asn = a.address.streetNumber;
    let afn = a.address.floorNumber;
    let adn = a.address.doorNumber;
    let bs = b.address.streetName;
    let bsn = b.address.streetNumber;
    let bfn = b.address.floorNumber;
    let bdn = b.address.doorNumber;
    let daSearch = new Intl.Collator("da", {
        numeric: true,
        sensitivity: "base",
    });
    let regEx = /[A-Za-z]/g;

    //DIY floornumber sort function to return "kl" and "st" before numbers

    let fnsearch = function (afn, bfn) {
        if (regEx.test(afn)) {
            if (regEx.test(bfn)) {
                return daSearch.compare(afn, bfn);
            } else {
                return "-1";
            }
        } else if (regEx.test(bfn)) {
            return "1";
        } else if (parseInt(afn) > parseInt(bfn)) {
            return "1";
        } else if (parseInt(bfn) > parseInt(afn)) {
            return "-1";
        } else {
            return "0";
        }
    };

    // Compare by streetname first, and if they match, then compare by streetnumber, then custom
    // floornumber, then doornumber
    let result = daSearch.compare(as, bs) || daSearch.compare(asn, bsn) || fnsearch(afn, bfn)
                 || daSearch.compare(adn, bdn);
    return result;
};

export const uniqueElementsBy = (arr, fn) =>
    arr.reduce((acc, v) => {
        if (!acc.some(x => fn(v, x))) {
            acc.push(v);
        }
        return acc;
    }, []);
