
import { createListPropertiesSheet, createListCondosSheet, writeSheetsToXSLXAndSave } from "~/helpers/download-helpers.js";

import ListFull from "../../../../graphql/Lists/ListFull.gql";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      exportLoading: false,

      mainpropertiesKeys: [
        { val: true, name: this.$t("LIST_EXPORT_ADDRESS"), value: "addressText" },
        { val: true, name: this.$t("LIST_EXPORT_MATEJER"), value: "matEjerlav" },
        { val: true, name: this.$t("LIST_EXPORT_PROPTYPE"), value: "propertyType" },
        { val: true, name: this.$t("LIST_EXPORT_OLDEST_CONSTRUCTION_YEAR"), value: "oldestConstructionYear" },
        { val: true, name: this.$t("LIST_EXPORT_ALL_CONSTRUCTION_YEARS"), value: "allConstructionYears" },
        { val: true, name: this.$t("LIST_EXPORT_EXTENSION_YEAR"), value: "extensionYears" },
        { val: true, name: this.$t("LIST_EXPORT_DESC"), value: "description" },
        { val: true, name: this.$t("LIST_EXPORT_STATUS"), value: "status" },
        { val: true, name: this.$t("LIST_EXPORT_FLOORS"), value: "noOfFloors" },
        { val: true, name: this.$t("LIST_EXPORT_TOTALNOOFROOMS"), value: "noOfRooms" },
        // { val: true, name: this.$t("LIST_EXPORT_RESIDENTIALAREA"), value: "residentialArea" },
        { val: true, name: this.$t("LIST_EXPORT_BUILDINGAREA"), value: "buildingArea" },
        { val: true, name: this.$t("LIST_EXPORT_SITEAREA"), value: "siteArea" },
        { val: true, name: this.$t("LIST_EXPORT_COMMERCIALAREA"), value: "commercialArea" },
        { val: true, name: this.$t("LIST_EXPORT_WEIGHTED_AREA"), value: "weightedArea" },
        { val: true, name: this.$t("LIST_EXPORT_PUBVAL"), value: "publicValuation" },
        { val: true, name: this.$t("LIST_EXPORT_SALESDATE"), value: "latestSalesDate" },
        { val: true, name: this.$t("LIST_EXPORT_SALESSUM"), value: "latestSalesSum" },
        { val: true, name: this.$t("C_TRANS_CLAIMS"), value: "claims" },
      ],

      condosKeys: [
        { val: true, name: this.$t("LIST_EXPORT_ADDRESS"), value: "addressText" },
        { val: true, name: this.$t("LIST_EXPORT_PROPTYPE"), value: "propertyType" },
        { val: true, name: this.$t("LIST_EXPORT_CONSTRUCTION_YEAR"), value: "constructionYear" },
        { val: true, name: this.$t("LIST_EXPORT_EXTENSION_YEAR"), value: "extensionYears" },
        { val: true, name: this.$t("LIST_EXPORT_DESC"), value: "description" },
        { val: true, name: this.$t("LIST_EXPORT_STATUS"), value: "status" },
        { val: true, name: this.$t("LIST_EXPORT_FLOOR"), value: "floorNo" },
        { val: true, name: this.$t("LIST_EXPORT_NOOFROOMS"), value: "noOfRooms" },
        { val: true, name: this.$t("LIST_EXPORT_TOTALAREA"), value: "totalArea" },
        { val: true, name: this.$t("LIST_EXPORT_RESIDENTIALAREA"), value: "residentialArea" },
        { val: true, name: this.$t("LIST_EXPORT_COMMERCIALAREA"), value: "commercialArea" },
        { val: true, name: this.$t("LIST_EXPORT_WEIGHTED_AREA"), value: "weightedArea" },
        // { val: true, name: this.$t("LIST_EXPORT_INMAINPROPERTY"), value: "inMainProperty" },
        { val: true, name: this.$t("LIST_EXPORT_PUBVAL"), value: "publicValuation" },
        { val: true, name: this.$t("LIST_EXPORT_SALESDATE"), value: "latestSalesDate" },
        { val: true, name: this.$t("LIST_EXPORT_SALESSUM"), value: "latestSalesSum" },
        { val: true, name: this.$t("C_TRANS_CLAIMS"), value: "claims" },
      ],
    };
  },

  methods: {
    async exportList() {
      this.exportLoading = true;

      const result = await this.$apollo.query({
        query: ListFull,
        fetchPolicy: "no-cache",
        variables: { input: this.$route.params.id },
      });

      const properties = result.data.list.listProperties?.filter((x) => x.property?.plots);
      const condos = result.data.list.listProperties?.filter((x) => x.property?.condo);

      const propertiesSheet = createListPropertiesSheet(
        properties || [],
        this.mainpropertiesKeys.filter((x) => x.val)
      );

      const condoSheet = createListCondosSheet(
        condos || [],
        this.condosKeys.filter((x) => x.val)
      );

      this.exportLoading = false;

      writeSheetsToXSLXAndSave([propertiesSheet, condoSheet], result.data.list.name);

      this.$emit("download");

      this.$amplitude.track({
        event_type: "Export list",
        event_properties: {
          origin: "add to list",
          selectedMainPropertValues: this.mainpropertiesKeys.filter((x) => x.val).map((x) => x.name),
          selectedCondoValues: this.condosKeys.filter((x) => x.val).map((x) => x.name),
        },
      });
    },
  },
};
