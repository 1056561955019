
import PropertyCard from "~/components/EntityCard/PropertyCard.vue";
import { getPropertyAddress } from "~/helpers/property-helpers.js";
import { getPropertyIcon } from "~/helpers/property-helpers.js";
import { mapPropertyRemarks } from "~/helpers/property-helpers.js";

export default {
  components: {
    PropertyCard,
  },

  inheritAttrs: false,

  props: {
    property: {
      type: Object,
      required: true,
    },

    description: {
      type: String,
      default: "--",
    },

    trackingOrigin: {
      type: String,
      required: true,
    },

    shadow: {
      type: Boolean,
      default: false,
    },

    rounded: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      descriptionInternal: this.description,
    };
  },

  computed: {
    address() {
      return getPropertyAddress(this.property);
    },

    propertyType: function () {
      if (this.property.condo != null) {
        return "condo";
      } else if (this.property.isDividedInCondos) {
        return "propertyDividedInCondos";
      } else if (this.property.buildingOnForeignPlot) {
        return "buildingOnForeignPlot";
      } else {
        return "mainProperty";
      }
    },

    propertyTypeString() {
      if (this.propertyType == "condo") {
        return this.$t("OVERVIEW_TYPE_CONDO");
      } else if (this.propertyType == "propertyDividedInCondos") {
        return this.$t("OVERVIEW_TYPE_PROPERTY_WITH_CONDOS");
      } else if (this.propertyType == "buildingOnForeignPlot") {
        return this.$t("OVERVIEW_TYPE_BOFP");
      } else {
        return this.$t("OVERVIEW_TYPE_MAIN_PROPERTY");
      }
    },

    owners() {
      return this.property.owners?.filter((x) => x.name != "") || [];
    },

    administrators() {
      return this.property.administrators?.filter((administrator) => administrator.name != "" || administrator.cvrNumber);
    },

    showDescriptionEditButtons() {
      return this.description != this.descriptionInternal;
    },

    lists() {
      return this.property.listProperties?.map((lp) => lp.list) || [];
    },

    remarks() {
      return mapPropertyRemarks(this.property);
    },
  },

  watch: {
    description() {
      this.descriptionInternal = this.description;
    },
  },

  methods: {
    propertyCardNavigate() {
      this.$emit("close-all");
    },

    saveDescription() {
      this.$emit("save-description", this.descriptionInternal);
    },

    cancelEditDescription() {
      this.descriptionInternal = this.description;
    },

    onInput(description) {
      this.descriptionInternal = description;
    },

    getPropertyIcon: getPropertyIcon,
  },
};
