// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_JvV8s{display:flex;flex-direction:column;position:relative}.compositionLayoutGrid_7GYl-,.container_JvV8s{overflow:auto;scroll-behavior:smooth;-webkit-overflow-scrolling:touch}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_JvV8s",
	"compositionLayoutGrid": "compositionLayoutGrid_7GYl-"
};
module.exports = ___CSS_LOADER_EXPORT___;
