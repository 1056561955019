// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".buttons_STf0y{display:flex;flex-direction:row}.draggableZone_1kxkD{border-right:1px dashed var(--color-supportive-3-500);height:100%;padding:1rem 1rem 3.5rem}.transitionGroup_ZN5CN{height:100%;min-height:25rem}.hrLine_7x8Rj{border:.1px solid var(--color-black-50)}.infoLine_cjhPS{display:flex;flex-direction:row}.comment_Y32kM{margin-bottom:.5rem;margin-top:.5rem;padding:.5rem 1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": "buttons_STf0y",
	"draggableZone": "draggableZone_1kxkD",
	"transitionGroup": "transitionGroup_ZN5CN",
	"hrLine": "hrLine_7x8Rj",
	"infoLine": "infoLine_cjhPS",
	"comment": "comment_Y32kM"
};
module.exports = ___CSS_LOADER_EXPORT___;
