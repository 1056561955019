
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    deleteStatus() {
      this.$store.dispatch("lists/deleteListStatus", {
        statusId: this.$store.getters["lists/getStatusForDeletion"].id,
        listId: this.$store.getters["lists/getListId"],
      });
      this.$amplitude.track({ event_type: "Delete state" });
    },

    cancel() {
      this.$store.commit("lists/setStatusForDeletion", null);
    },
  },
};
