// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_WGR8i{flex-direction:column}.container_WGR8i,.containerLayoutSplitEnd_tjm00,.containerLayoutSplitStart_rivwY{display:flex}.containerLayoutSplitStart_rivwY{flex-direction:column;flex-grow:1}.containerLayoutSplitEnd_tjm00{justify-content:flex-end}.controls_vmfUJ,.header_Txz3I{display:flex}.controls_vmfUJ{height:-moz-min-content;height:min-content;justify-content:flex-end;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_WGR8i",
	"containerLayoutSplitEnd": "containerLayoutSplitEnd_tjm00",
	"containerLayoutSplitStart": "containerLayoutSplitStart_rivwY",
	"controls": "controls_vmfUJ",
	"header": "header_Txz3I"
};
module.exports = ___CSS_LOADER_EXPORT___;
