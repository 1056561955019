
import ListsForProperty from "~/graphql/Lists/ListsForProperty.gql";
import ListPropertyFull from "~/graphql/Lists/ListPropertyFull.gql";

import ListPropertyNote from "../../../../components/list/ListPropertyNote.vue";

import LayoutListEntity from "./LayoutListEntity.vue";
import AddNote from "./AddNote.vue";
import PropertyCard from "./PropertyCard.vue";

import { mapPropertyRemarks } from "~/helpers/property-helpers.js";

export default {
  components: {
    LayoutListEntity,
    ListPropertyNote,
    AddNote,
    PropertyCard,
  },

  props: {
    id: {
      type: String,
      default: null,
    },

    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      deleteNoteShowConfirm: false,
      deleteEntityShowConfirm: false,
      noteSlatedForDeletion: null,
    };
  },

  computed: {
    listEntity() {
      return this.listProperty || null;
    },

    listsForProperty: function () {
      return this.listProperty.property?.listProperties?.map((lp) => lp.list) ?? [];
    },

    remarks() {
      return mapPropertyRemarks(this.listProperty.property);
    },

    statuses() {
      return this.$store.getters["lists/getStatuses"];
    },

    notes() {
      let notes = this.listProperty?.notes ? [...this.listProperty.notes] : [];

      return notes.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());
    },
  },

  apollo: {
    listsForProperty: {
      query: ListsForProperty,

      variables: function () {
        return { bfeNumber: this.listEntity?.bfeNumber };
      },

      update: function (data) {
        return data.listPropertiesForProperty;
      },

      skip: function () {
        return !this.listEntity;
      },
    },

    listProperty: {
      query: ListPropertyFull,

      variables: function () {
        return { input: this.id };
      },

      skip: function () {
        return !this.id;
      },
    },
  },

  methods: {
    closeAll() {
      this.deleteNoteShowConfirm = false;
      this.deleteEntityShowConfirm = false;

      this.$emit("close");
    },

    navigateToList(item) {
      this.closeAll();

      this.$router.push({
        name: "my-estaid-lists-id",
        params: { id: item.list.id },
      });

      this.$amplitude.track({
        event_type: "Open list",
        event_properties: {
          route: "List item modal",
        },
      });
    },

    navigateToEntity(route) {
      let routeData;

      if (this.listEntity.__typename == "ListProperty") {
        routeData = this.$router.resolve({
          name: "explore-property-id",
          params: { id: this.listEntity.bfeNumber },
        });

        this.$amplitude.track({
          event_type: "Property lookup",
          event_properties: {
            bfeNumber: this.listEntity.bfeNumber,
            route: route,
          },
        });
      }

      routeData && window.open(routeData.href, "_blank");
    },

    addNote(noteText) {
      if (noteText.length > 0) {
        this.$store.dispatch("lists/createNote", {
          listPropertyId: this.id,
          message: noteText,
        });

        this.$amplitude.track({
          event_type: "Create note",
          event_properties: {
            bfeNumber: this.listEntity.bfeNumber,
          },
        });
      }
    },

    deleteNote(note) {
      this.noteSlatedForDeletion = note;
      this.deleteNoteShowConfirm = true;
    },

    deleteNoteConfirm() {
      if (this.noteSlatedForDeletion) {
        this.$store.dispatch("lists/deleteNote", this.noteSlatedForDeletion);

        this.deleteNoteShowConfirm = false;
        this.noteSlatedForDeletion = null;

        this.$amplitude.track({
          event_type: "Delete note",
          event_properties: {
            bfeNumber: this.listEntity.bfeNumber,
          },
        });
      }
    },

    deleteNoteCancel() {
      this.noteSlatedForDeletion = null;
      this.deleteNoteShowConfirm = false;
    },

    removeListEntity() {
      this.deleteEntityShowConfirm = true;
    },

    removeListEntityConfirm() {
      this.deleteEntityShowConfirm = false;

      this.$emit("close");

      // TO-DO: luppilau - add support for deletion of ListCompanies
      if (this.listEntity.__typename == "ListProperty") {
        this.$store.dispatch("lists/deleteListEntity", this.listEntity.id);
      }

      this.$amplitude.track({ event_type: "Delete list item" });
    },

    removeListEntityCancel() {
      this.deleteEntityShowConfirm = false;
    },

    saveDescription(description) {
      const updateListEntity = {
        id: this.id,
        description: description,
        type: this.listEntity.__typename,
      };

      this.$store.dispatch("lists/updateListEntityDescription", updateListEntity);

      this.$amplitude.track({
        event_type: "Write description",
        event_properties: {
          origin: "list item",
          address: this.id,
        },
      });
    },
  },
};
