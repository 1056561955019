// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".containerTopControlsPushButton_0bEyc{transition-duration:var(--transition-duration-shortest);transition-property:transform;transition-timing-function:ease-in-out}.containerTopControlsPushButton_0bEyc:hover{transform:scale(1.1)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerTopControlsPushButton": "containerTopControlsPushButton_0bEyc"
};
module.exports = ___CSS_LOADER_EXPORT___;
