
import { getPropertyAddress } from "~/helpers/property-helpers.js"
import CompanyLink from "../../../../components/EntityLink/components/CompanyLink.vue"
import { PROPERTY_TYPE_MAIN_PROPERTY, PROPERTY_TYPE_CONDO, PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT, PROPERTY_TYPE_UNKNOWN, getPropertyType } from "~/helpers/property-helpers.js"

export default {
    name: "TableEntityItem",

    components: {
      CompanyLink,
    },

    inheritAttrs: false,

    props: {
      data: {
        type: Object,
        required: true
      }
    },

    methods: {
      getAddress(item){
        return getPropertyAddress(item.property);
      },

      getPropertyIcon(data) {
        switch(getPropertyType(data.property)) {
          case PROPERTY_TYPE_MAIN_PROPERTY:
            return "icon-property-tt";
          case PROPERTY_TYPE_CONDO:
            return "icon-condo-tt";
          case PROPERTY_TYPE_BUILDING_ON_FOREIGN_PLOT:
            return "icon-building-tt"
          default:
            return "icon-property-tt";
        }
      }
    }
}
