import { render, staticRenderFns } from "./SortableTable.vue?vue&type=template&id=dce1d0b8"
import script from "./SortableTable.vue?vue&type=script&lang=js"
export * from "./SortableTable.vue?vue&type=script&lang=js"
import style0 from "./SortableTable.vue?vue&type=style&index=0&id=dce1d0b8&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ColumnToggleItem: require('/app/components/SortableTable/components/ColumnToggleItem.vue').default,ColumnToggle: require('/app/components/SortableTable/components/ColumnToggle.vue').default,TickBox: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/TickBox/TickBox.vue').default,SimpleTableCell: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/SimpleTable/SimpleTableCell.vue').default,Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,SimpleTableRow: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/SimpleTable/SimpleTableRow.vue').default,SimpleTable: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/SimpleTable/SimpleTable.vue').default})
