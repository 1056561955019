
export default {
  inheritAttrs: false,

  data: function () {
    return {
      downloadConditionsConfirmed: false,
    };
  },
};
