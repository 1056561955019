
import KanbanItemCompany from "./KanbanItemCompany.vue";
import KanbanItemProperty from "./KanbanItemProperty.vue";

export default {
  name: "KanbanItem",

  components: {
    KanbanItemCompany,
    KanbanItemProperty,
  },

  inheritAttrs: true,

  props: {
    selectedId: {
      type: String,
      required: true,
    },

    item: {
      type: Object,
      required: true,
    },
  },
};
