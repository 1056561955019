
export default {
  name: "KanbanItemCompany",

  inheritAttrs: false,

  props: {
    item: {
      type: Object,
      required: true
    }
  },
}
