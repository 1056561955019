// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_3kQwn,.link_hcdpy{display:inline-flex}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_3kQwn",
	"link": "link_hcdpy"
};
module.exports = ___CSS_LOADER_EXPORT___;
