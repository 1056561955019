// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_sQM\\+J{align-items:center;border-bottom:1px dashed var(--color-supportive-3-900);border-right:1px dashed var(--color-supportive-3-900);display:flex;justify-content:center;padding-bottom:.5rem;padding-top:.25rem;-webkit-user-select:none;-moz-user-select:none;user-select:none}.unsetBorder_6hjeN{border-right:initial}.create_MJsai{align-items:center;cursor:pointer;display:flex;justify-content:center;position:relative}.createIcon_CaedX{display:flex;margin-right:.25rem}.updateAndDelete_3HQsh{align-items:center;display:flex;justify-content:center;position:relative}.container_sQM\\+J:hover .updateAndDeleteIcon_WwS8-{opacity:1}.updateAndDeleteIcon_WwS8-{cursor:pointer;display:flex;left:.5rem;opacity:0;position:absolute;transition-duration:var(--transition-duration-shortest);transition-property:opacity transform;transition-timing-function:ease-in-out}.updateAndDeleteIcon_WwS8-:hover{transform:scale(1.2)}.updateAndDeleteIcon_WwS8-:last-child{left:2rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_sQM+J",
	"unsetBorder": "unsetBorder_6hjeN",
	"create": "create_MJsai",
	"createIcon": "createIcon_CaedX",
	"updateAndDelete": "updateAndDelete_3HQsh",
	"updateAndDeleteIcon": "updateAndDeleteIcon_WwS8-"
};
module.exports = ___CSS_LOADER_EXPORT___;
