// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_99mJq{display:flex;flex-direction:column;margin-bottom:1rem}.container_99mJq:last-child{margin-bottom:0}.container_99mJq:hover .delete_4d\\+Wx{opacity:1}.title_TRM6S{align-items:center;display:inline-flex}.timestamp_G8o-\\+{margin-left:.5rem;opacity:.4}.delete_4d\\+Wx{opacity:0;transition-duration:var(--transition-duration-shortest);transition-property:transform opacity;transition-timing-function:ease-in-out}.delete_4d\\+Wx:hover{transform:scale(1.1)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_99mJq",
	"delete": "delete_4d+Wx",
	"title": "title_TRM6S",
	"timestamp": "timestamp_G8o-+"
};
module.exports = ___CSS_LOADER_EXPORT___;
