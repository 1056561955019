import { render, staticRenderFns } from "./PropertyCard.vue?vue&type=template&id=575cf172"
import script from "./PropertyCard.vue?vue&type=script&lang=js"
export * from "./PropertyCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PropertyLink: require('/app/components/EntityLink/PropertyLink.vue').default,Icon: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Icon/Icon.vue').default,Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,KeyValuePair: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/KeyValuePair/KeyValuePair.vue').default,TextField: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/TextField/TextField.vue').default,CompositionSpacer: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Composition/CompositionSpacer.vue').default,PushButton: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/PushButton/PushButton.vue').default,OwnerChip: require('/app/components/EntityLink/OwnerChip.vue').default,ChipContainerRow: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Chip/ChipContainerRow.vue').default,PropertyCard: require('/app/components/EntityCard/PropertyCard.vue').default})
