
import ModalCreateStatus from "./components/ModalStatusCreate.vue";
import ModalRenameStatus from "./components/ModalStatusRename.vue";
import ModalDeleteStatus from "./components/ModalStatusDelete.vue";

import Header from "./components/Header.vue";
import ViewKanban from "./components/ViewKanban.vue";
import ViewList from "./components/ViewList.vue";

export default {
  components: {
    ModalCreateStatus,
    ModalRenameStatus,
    ModalDeleteStatus,
    Header,
    ViewKanban,
    ViewList,
  },

  inheritAttrs: false,

  data() {
    return {
      currentView: "list",
    };
  },

  watch: {
    // Luppilau: to handle case where user navigates between lists with navigation buttons
    $route() {
      this.$store.dispatch("lists/fetchListByIdWithLoading", this.$route.params.id);
    },

    currentView: function (newVal) {
      if (newVal == "kanban") {
        this.$amplitude.track({ event_type: "Enable kanban" });
      } else if (newVal == "list") {
        this.$amplitude.track({ event_type: "Enable list" });
      }

      this.$userPreferences.setKey("preferenceNamespaceLists", "listView", newVal);
    },
  },

  mounted() {
    this.$store.dispatch("lists/fetchListByIdWithLoading", this.$route.params.id);

    this.$userPreferences.getKey("preferenceNamespaceLists", "listView").then((currentView) => {
      this.currentView = currentView;
    });
  },
};
